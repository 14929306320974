import { render, staticRenderFns } from "./culturalRelicsImitation.vue?vue&type=template&id=7ca8057e&scoped=true&"
import script from "./culturalRelicsImitation.vue?vue&type=script&lang=js&"
export * from "./culturalRelicsImitation.vue?vue&type=script&lang=js&"
import style0 from "./culturalRelicsImitation.vue?vue&type=style&index=0&id=7ca8057e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ca8057e",
  null
  
)

export default component.exports