<template>
  <div class="outside">
    <!-- <banner /> -->
    <div class="top-banner">
      <img src="../../assets/img/culturalRelicsImitation/banner.jpg" alt="" srcset="">
    </div>
    <div class="introduction">
      <div class="edition_heart">
        <div class="title">文物展品复仿制工作部简介</div>
        <div class="titleEng">Introduction to the Department of Replica and Imitation </div>
        <div class="content">
          在多年的文物保护修复实践当中，山东文保积累了对文物形貌肌理的深刻认识。随着各博物馆新馆建设中展陈扩大的需求，山东文保自2017年后逐渐开展了文物
          复、仿制业务。可制作的展品种类包含书法、绘画、陶瓷器、青铜器、铁器、漆器、石碑石刻、玉器、石器、金银器、化石等。
          复仿制文物在山东、江苏、江西、甘 肃等多个博物馆中展出。
        </div>
        <div class="content">
          目前公司配备了高精度三维扫描设备、三维输出设备、专业书画扫描设备、艺术品打印输出设备等一大批高端文物复仿制设备。
          可做到文物非接触形貌扫描，高 还原度复制及等比例仿制。满足了博物馆展陈需求。
        </div>
        <div class="photos">
          <el-row>
            <el-col :span="8" v-for="(it, index) in introductionImg" :key="index">
              <img :src="it.imgUrl" alt="" />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="repair_case">
      <div class="edition_heart">
        <div class="title">复仿制案例</div>
        <div class="titleEng">Copying cases</div>
        <swiper ref="mySwiper" :options="swiperOption" class="classification" :autoplay="true">
          <swiper-slide v-for="(item, index) in imitationData" :key="index">
            <div class="case-type-title" :class="[typeIndex === index ? 'case-type-select' : '']"
              @click="changeClassification(index)">{{ item.title }}</div>
          </swiper-slide>
        </swiper>
        <!-- <div class="after">原件复制件对比</div> -->
        <div class="photos">
          <swiper ref="mySwiper" :options="swiperOptions" style="width: 100%" :autoplay="true">
            <swiper-slide v-for="(item, index) in imitationData[index].CopyCaseImg" :key="index">
              <div class="repair_case_photos">
                <img ref="swiperImg" :src="item.imgUrl" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from '/src/components/banner.vue'
// vue-awesome-swiper@4.x 版本首字母 S 要用大写
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'culturalRelicsImitation',
  components: { banner, Swiper, SwiperSlide },
  data() {
    return {
      // 案例选中的类型索引
      typeIndex: 0,
      introductionImg: [
        { imgUrl: require('/src/assets/img/culturalRelicsImitation/CopyCase1.jpg') },
        { imgUrl: require('/src/assets/img/culturalRelicsImitation/CopyCase2.jpg') },
        { imgUrl: require('/src/assets/img/culturalRelicsImitation/CopyCase3.jpg') },
      ],
      imitationData: [{
        title: '金属文物',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/jins1.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/jins2.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/jins3.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/jins4.jpg') }
        ]
      }, {
        title: '陶瓷器',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/CopyCaseImg4.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/CopyCaseImg6.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/taoc.jpg') },
        ]
      }, {
        title: '木佣',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/muy1.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/muy2.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/muy3.jpg') },
        ]
      }, {
        title: '青铜器',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/qingt1.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/qingt2.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/qingt3.jpg') },
        ]
      }, {
        title: '铁器',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/tieq1.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/tieq2.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/tieq3.jpg') },
        ]
      }, {
        title: '漆器',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/qiq3.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/qiq1.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/qiq2.jpg') },
        ]
      }, {
        title: '石器',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/shiq1.jpg') },
        ]
      }, {
        title: '玉器',
        CopyCaseImg: [
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/yuq1.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/yuq2.jpg') },
          { imgUrl: require('/src/assets/img/culturalRelicsImitation/yuq3.jpg') },
        ]
      }],
      index: 0,
      isMounted: false,
      swiperOption: {
        slidesPerView: 'auto', //一行显示3个
        spaceBetween: 40, //间隔10
        freeMode: true,
        speed: 1000, //滑动速度
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOptions: {
        slidesPerView: 3, //一行显示3个
        spaceBetween: 10, //间隔10
        freeMode: true,
        speed: 1000, //滑动速度
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: true,
        autoplay: {
          //这个参数很重要,当外力干扰轮播图时，它能够继续实现自我滚动
          disableOnInteraction: false,
          delay: 5000, //5秒切换一次
        },
      },
    }
  },
  mounted() {
    this.isMounted = true
  },
  computed: {
    customswiper() {
      let swiper
      if (this.isMounted) swiper = this.$refs.mySwiper.$swiper
      return swiper
    },
  },
  methods: {
    changeClassification(index) {
      this.index = index
      this.typeIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.outside {
  background-color: #F9F7F3;
}

.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 1920px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.repair_case {
  background: url(../../assets//img/culturalRelicsImitation/bg.jpg) center center no-repeat;
  background-size: cover;
}

.case-type-title {
  cursor: pointer;
  padding: 10px;
}

.case-type-select {
  background: #A40000;
  color: #fff;
}

.classification /deep/ .swiper-wrapper {
  justify-content: flex-end;
}

@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 70px;
      font-size: 32px;
    }

    .classification {
      font-size: 20px;
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 70px;
      font-size: 32px;
    }

    .classification {
      font-size: 20px;
      width: 79%;
      top: 130px;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    display: none;
  }

  .edition_heart {
    width: 90%;
  }

  .outside {
    padding-top: 60px;
  }

  .introduction {
    .title {
      padding-top: 20px;
    }
  }

  .edition_heart {
    padding-top: 130px;
  }

  .repair_case {
    .title {
      padding-top: 30px;
      font-size: 24px;
    }

    .classification {
      width: 60%;
      font-size: 16px;
      top: 65px;
    }
  }

  ::v-deep .edition_heart {
    padding-top: 30px;
  }
}

.outside {

  // padding-top: 190px;
  .introduction {
    width: 100%;

    .title {
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
      margin-bottom: 45px;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .content {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666;
      line-height: 30px;
      text-indent: 2em;
    }

    .photos {
      width: 100%;
      height: auto;
      margin: 80px 0 30px;
      padding: 10px 0 10px 10px;
      margin: 20px 0 60px;

      img {
        width: 95%;
        height: auto;
        border: 5px solid rgba(255, 255, 255, 0.4);
      }
    }
  }

  .repair_case {
    width: 100%;
    color: #3d3d3d;
    position: relative;

    .title {
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
      margin-bottom: 45px;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .classification {
      .swiper-slide {
        width: auto;
      }
    }

    .after {
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Normal;
      margin: 38px 0 18px;
      position: absolute;
      right: 0;
    }

    .photos {
      padding: 40px 0 100px;

      .repair_case_photos {
        cursor: pointer;
        width: 100%;

        img {
          width: 96%;
          height: auto;
          border: 5px solid rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  // 版心
  .edition_heart {
    // width: 62.5%;
    margin: 0px auto;
    position: relative;
  }
}
</style>
